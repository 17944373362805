const config = {
  "broker": "3",
  "name": "Greek",
  "registrationBundle": "greek",
  "defaultCountryName": "Greece",
  "defaultCountryCode": "GR",
  "fxgeoip_code": "GR",
  languageCode: 'el',
  languageName:'Greek',
  spokenLanguageCode:'el',
  direction:'ltr',
  footerDisclaimer:'897',
  brand: 'iforex'
};
module.exports = config;